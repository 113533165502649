<template>
  <div class="info-con">
    <div class="info-con-title">完善资料</div>
    <div class="info-con-top">
      <span>会员信息</span>
    </div>
    <div class="info-form">
      <div class="info-form-text">
        <label for="phone">姓名</label>
        <input
          v-model="form.real_name"
          autofocus
          id="phone"
          type="text"
          placeholder="我是昵称"
        />
      </div>
      <div class="info-form-text">
        <label for="phone">手机号</label>
        <input
          v-model="form.mobile"
          autofocus
          id="phone"
          type="tel"
          disabled
          placeholder="请填写手机号码"
        />
      </div>
      <div class="info-form-text">
        <label for="sex">性别</label>
        <radio-group v-model="form.sex" class="info-sex-con">
          <radio name="1">男</radio>
          <radio name="2" style="margin-left:10px">女</radio>
        </radio-group>
      </div>
      <div class="info-form-text" @click="showTime = !showTime">
        <label for="sday">生日</label>
        <input
          disabled
          v-model="form.birthday"
          autofocus
          id="sday"
          type="text"
          placeholder="2019/08/12"
        />
      </div>
      <div class="info-form-text">
        <label for="card_num">车牌号</label>
        <input
          v-model="form.device"
          autofocus
          id="card_num"
          type="text"
          placeholder="鲁A 66666"
        />
      </div>
      <div class="info-form-text">
        <label for="oiltype">常用油品</label>
        <input
          v-model="form.product"
          autofocus
          id="oiltype"
          type="text"
          placeholder="#92"
        />
      </div>
      <div class="go-btn">
        <Button
          type="primary"
          block
          @click="pushForm"
          color="linear-gradient(90deg,rgba(237,102,77,1) 0%,rgba(245,149,122,1) 100%)"
        >
          确认
        </Button>
      </div>
     <div class="go-btn">
        <Button
          to="/vip"
          type="default"
          block
        >
          跳过
        </Button>
      </div>
      <Popup v-model="showTime" position="bottom" :style="{ height: '40%' }">
        <DatetimePicker
          v-model="currentDate"
          type="date"
          @cancel="showTime = false"
          @confirm="setTime"
          :min-date="minDate"
          :max-date="maxDate"
        >
        </DatetimePicker>
      </Popup>
    </div>
  </div>
</template>
<script>
import { Button, RadioGroup, Radio, Popup, DatetimePicker } from "vant";
import Setting from "@api/setting";
import UserInfo from "@api/user";
import time from "@/lin/filter/time";
export default {
  components: {
    Button,
    RadioGroup,
    Radio,
    Popup,
    DatetimePicker
  },
  data() {
    return {
      myInfo: {},
      showTime: false,
      form: {
        real_name: "",
        mobile: "",
        sex: "1",
        birthday: "",
        device: "",
        product: ""
      },
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  mounted() {
    this.$nextTick(() => {
      // this.getUserInfo();
      this.MygetVipList();
    });
  },
  methods: {
    // 获取我的会员卡信息
    async MygetVipList() {
      try {
        const res = await UserInfo.my_card();
        if (res.code == 200) {
          this.form = res.data.user_info;
        } else {
          throw (res.msg)
        }
      } catch (error) {
        console.log(error);
      }
    },
    setTime(val) {
      this.form.sday = time.setTime(new Date(val).getTime() / 1000);
      this.showTime = false;
    },
    // 提交
    async pushForm() {
      try {
        const res = await UserInfo.changeUserInfo(
          this.myInfo.id,
          this.form.real_name,
          this.form.mobile,
          this.form.sex,
          this.form.birthday,
          this.form.device,
          this.form.product
        );
        if (res.code == 200) {
          this.$toast(res.msg);
          // let status = await this.vipUserStatus();
          // if (!status) {
          //   this.$router.push("/vip");
          // } else {
          //   this.$router.push("/password");
          // }
        } else {
          throw(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async vipUserStatus() {
      try {
        const res = await UserInfo.isUserInfo();
        if (res.code == 200) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserInfo() {
      try {
        const res = await Setting.GetInfo();
        if (res.code == 200) {
          this.myInfo = res.data;
          this.form.name = res.data.real_name;
          this.form.phone = res.data.mobile;
        } else if (res.code == 0) {
          if (this.isWeixin_status) {
            this.$router.push("/bindPhone");
          } else {
            this.$dialog
              .confirm({
                title: res.msg
              })
              .then(() => {
                this.$router.push("/userlogin");
              })
              .catch(() => {});
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.info-con {
  width: 100%;
  height: 100vh;
  background: rgba(248, 248, 248, 1);
  .info-con-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    background: #ffffff;
  }
  .info-con-top {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 16px;
    color: #999999;
  }
  .info-form {
    width: 100%;
    height: 88vh;
    padding: 0 14px;
    overflow: hidden;
    background: #ffffff;
    .info-form-text {
      width: 100%;
      height: 50px;
      font-size: 13px;
      color: #999999;
      display: flex;
      font-weight: 500;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eeeee0;
    }
    .info-form-text {
      color: #333333;
      input {
        width: 76%;
        display: inline-block;
        border: none;
        padding-left: 30px;
        background: none;
      }
      .info-sex-con {
        display: flex;
        width: 74%;
      }
    }
    .go-btn {
      width: 92%;
      height: 40px;
      margin: 20px auto;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
    }
  }
}
</style>
